import React, { Component } from 'react';
import queryString from 'query-string';
import authService from '../../services/authService';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

class ConfirmTeam extends Component {

    constructor(props) {
        super(props);
        const { search } = props.location;
        const params = queryString.parse(search);

        let token = '';
        let team = '';
        if (!params.token && !params.team) {
            props.history.push('/');
        }
        else {
            token = params.token;
            team = params.team;
        }

        this.state = {
            success: false,
            loading: false,
            error: '',
            token,
            team
        }
    }

    componentDidMount() {
        this.validateUser();
    }


    validateUser = () => {
        const { token, team } = this.state;
        this.setState({
            loading: true
        });

        authService.confirmTeam(token, team).then(({ data }) => {
            this.setState({
                success: true,
                loading: false,
                error: ''
            })
        }).catch(err => {
            let error = "Es ist ein unerwarteter Fehler aufgetreten.";
            if (err.response && err.response.status === 400) {
                error = err.response.data.error;
            }

            this.setState({
                error,
                loading: false,
                success: false
            })
        })
    }

    render() {
        if (authService.getCurrentUser()) return <Redirect to='/' />

        const { error, loading, success } = this.state;

        return (
            <React.Fragment>
                <div className="confirm-user">
                    {
                        loading &&
                        <h1>Bitte warten...</h1>
                    }
                    {
                        !loading && success &&
                        <h1>Du hast deine Email erfolgreich bestätigt.</h1>
                    }
                    {
                        !loading && !success && error &&
                        <h1>{error}</h1>
                    }
                    <Link to="/auth">Weiter zur Anmeldung</Link>
                </div>
            </React.Fragment>
        );
    }
}

export default ConfirmTeam;
import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="footer">
          <div className="womix__header-graphic">
            <svg
              version="1.1"
              className="header_layer header_graphic-layer-1"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 1497.5 125"
            >
              <polygon
                className="st0"
                points="0,126.21 229.5,30 389.5,78 769.5,15 955.5,68 1259.5,0 1562,132.5 0,132.5 "
              ></polygon>
            </svg>
            <svg
              version="1.1"
              className="header_layer header_graphic-layer-2"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 1497.5 125"
            >
              <polygon
                className="st0"
                points="0,126.21 229.5,30 389.5,78 769.5,15 955.5,68 1259.5,0 1562,132.5 0,132.5 "
              ></polygon>
            </svg>
            <svg
              version="1.1"
              className="header_layer header_graphic-layer-3"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 1497.5 125"
            >
              <polygon
                className="st0"
                points="0,126.21 229.5,30 389.5,78 769.5,15 955.5,68 1259.5,0 1562,132.5 0,132.5 "
              ></polygon>
            </svg>
          </div>
          <div className="footer-content">
            <div className="container-fluid">
              <div className="copyright">&#169; 2022 Tübingen AI Center</div>
              <div className="container-limit my-limit">
                <div className="footer-conten-wrapper">
                  <a
                    href="https://www.bw-ki.de/impressum"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Impressum<div className="div-helper"></div>
                  </a>
                  <a
                    href="https://www.bw-ki.de/datenschutz"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Datenschutz<div className="div-helper"></div>
                  </a>
                  <a
                    href="mailto:info@bw-ki.de"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Kontakt<div className="div-helper"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Footer;

import http from "./httpService";

const { REACT_APP_WEB_API_URL: web_api_url } = process.env;

export async function register(
  username,
  password,
  confirmPassword,
  school,
  postNumber,
  fullName,
  birthday,
  email,
  parentEmail,
  mannlich,
  weiblich,
  divers,
  wohnOrt,
  eventsCheck,
  yearSchoolExam
) {
  return await http.post(`${web_api_url}/user`, {
    username,
    password,
    confirmPassword,
    school,
    postNumber,
    fullName,
    birthday,
    email,
    parentEmail,
    mannlich,
    weiblich,
    divers,
    wohnOrt,
    eventsCheck,
    yearSchoolExam,
  });
}

export async function changePassword(password, confirmPassword, token) {
  return await http.post(`${web_api_url}/change-password`, {
    password,
    confirmPassword,
    token,
  });
}

export async function getUser() {
  return await http.get(`${web_api_url}/current-user`);
}

export async function getUserByUsername(id) {
  return await http.get(`${web_api_url}/user/${id}`);
}

export async function editUser(
  fullName,
  postNumber,
  gender,
  school,
  wohnOrt,
  yearSchoolExam
) {
  return await http.put(`${web_api_url}/user`, {
    fullName,
    postNumber,
    gender,
    school,
    wohnOrt,
    yearSchoolExam,
  });
}

export default {
  register,
  changePassword,
  getUser,
  getUserByUsername,
  editUser,
};

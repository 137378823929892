import * as types from "../constants/can-submit";
import * as moment from "moment";

export const canSubmit = (stage = "idea") => {
  return (dispatch) => {
    var deadline = moment(
      process.env.REACT_APP_IDEA_DEADLINE,
      "DD.MM.YYYY HH:mm:ss"
    );
    // console.log(process.env.REACT_APP_DEADLINE)
    if (stage === "project") {
      deadline = moment(
        process.env.REACT_APP_PROJECT_DEADLINE,
        "DD.MM.YYYY HH:mm:ss"
      );
      // console.log(deadline + moment.now());
    }
    const can = moment(deadline).diff(moment.now(), "seconds") > 0;
    dispatch({
      type: types.CAN_SUBMIT,
      canSubmit: can,
    });
  };
};

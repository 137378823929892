import moment from "moment";
import "moment/locale/de";

// Get the idea and project submission deadlines from the environment variables and format them into a human-readable string
const {
  REACT_APP_FRONTEND_IDEA_DEADLINE: app_idea_deadline,
  REACT_APP_FRONTEND_PROJECT_DEADLINE: app_project_deadline,
} = process.env;

export const getIdeaSubmissionDeadline = () => {
  let ideaSubmissionMoment = moment(app_idea_deadline, "DD.MM.YYYY").locale(
    "de"
  );
  return ideaSubmissionMoment.format("Do MMMM YYYY");
};

export const getProjectSubmissionDeadline = () => {
  let projectSubmissionMoment = moment(
    app_project_deadline,
    "DD.MM.YYYY"
  ).locale("de");
  return projectSubmissionMoment.format("Do MMMM YYYY");
};

export default {
  getIdeaSubmissionDeadline,
  getProjectSubmissionDeadline,
};

import { GET_MULTIPLE_QUESTIONS, EDIT_MULTIPLE_QUESTIONS, SUBMIT_MULTIPLE_QUESTIONS } from '../constants/questionTypes';

const initialState = {
    questions: null,
    success: null
}

export default function questions(state = initialState, action) {
    switch (action.type) {
        case GET_MULTIPLE_QUESTIONS: {
            return Object.assign({}, { questions: action.questions, success: null })
        }
        case EDIT_MULTIPLE_QUESTIONS: {
            return Object.assign({}, { questions: action.questions, success: null })
        }
        case SUBMIT_MULTIPLE_QUESTIONS: {
            return Object.assign({}, { questions: action.questions, success: action.success })
        }
        default:
            return state;
    }
}

import React, { Component } from "react";
import logo from "../img/logo_bwki.png";
import { Link } from "react-router-dom";
import Breadcrumb from "./breadcrumb";

class AppBanner extends Component {
  state = {
    showMenu: false,
    showBreadcrumbMenu: false,
    wire_image: "/images/icons/menu_icon.svg",
  };

  toggleMenu = () => {
    var wire_image = "";
    if (!this.state.showMenu) {
      wire_image = "/images/icons/menu_open.svg";
    } else {
      wire_image = "/images/icons/menu_icon.svg";
    }

    this.setState({
      showMenu: !this.state.showMenu,
      wire_image: wire_image,
    });
  };

  toggleBreadcrumbMenu = () => {
    this.setState({ showBreadcrumbMenu: !this.state.showBreadcrumbMenu });
  };

  componentDidMount = () => {
    console.log(this.props.path);
  };

  getBreadCrumbMenu = () => {
    console.log(window.path);
  };

  render() {
    const { role } = this.props;
    const { showMenu, wire_image } = this.state;
    return (
      <React.Fragment>
        <section className="app-banner-wrapper">
          <div className="container-fluid">
            <div className={`header-wrapper`}>
              {!role && (
                <div className="banner-grid">
                  <div className="banner-logo">
                    <Link to="/">
                      <img alt="logo" src={logo} />
                    </Link>
                    <Link to="/">
                      <h1>
                        {" "}
                        <strong>Bundeswettbewerb</strong> <br /> Künstliche
                        Intelligenz{" "}
                      </h1>
                    </Link>
                  </div>

                  <Breadcrumb
                    path={this.props.path}
                    toggleBreadcrumbMenu={this.toggleBreadcrumbMenu}
                  />

                  <img
                    onClick={this.toggleMenu}
                    className="menu-right"
                    src={wire_image}
                    alt="wire menu icon"
                  />
                </div>
              )}
            </div>
          </div>
        </section>
        {showMenu && (
          <div className="app-banner-dropdown">
            <ul>
              <li>
                <Link to="/" onClick={this.toggleMenu}>
                  Startseite
                </Link>
              </li>
              <li>
                <Link to="/user-profile" onClick={this.toggleMenu}>
                  Benutzerprofil
                </Link>
              </li>
              <li>
                <Link to="/team-findung" onClick={this.toggleMenu}>
                  Team finden
                </Link>
              </li>
              {/* <li><Link to='/submit-project/submit-postcards'  onClick={this.toggleMenu}>Postkartenwettbewerb</Link></li> */}
              {/* disabling link to postcard submission */}
              {/* <li><Link to="#" style={{ pointerEvents: "none", opacity: "0.25" }}>Postkartenwettbewerb</Link></li> */}

              <li>
                <a
                  href="https://www.bw-ki.de"
                  onClick={this.toggleMenu}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Bundeswettbewerb KI
                </a>
              </li>
              <li>
                <a
                  href="https://ki-kurs.org"
                  onClick={this.toggleMenu}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  KI-Kurs
                </a>
              </li>
              <Link to="/logout" className="logout">
                Abmelden
              </Link>
            </ul>
          </div>
        )}
        {showMenu && (
          <div
            className="overlay-panel-container"
            onClick={this.toggleMenu}
          ></div>
        )}
      </React.Fragment>
    );
  }
}

export default AppBanner;

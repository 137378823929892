import React, { Component } from "react";
import logo from "../img/bwki-competition-logo.png";
import { Link } from "react-router-dom";
import Footer from "./footer";
import auth from "../services/authService";
import "./entry-page/main.css";

class EntryPage extends Component {
  render() {
    if (auth.getCurrentUser()) this.props.history.push("/");
    return (
      <React.Fragment>
        <div className={`womix__backgroundcliptext`}>
          <section className="content entry">
            <div className="container-fluid home-wrapper margin-top-5">
              <div className="middle-content entry">
                <div className={`entry-left`}>
                  <img src={logo} alt="" />
                </div>
                <div className={`entry-right`}>
                  <div className="right-content-wrapper">
                    <span className={`title`}>
                      Registriere Dich <br />
                      und reiche <br />
                      Dein Projekt{" "}
                    </span>
                    <span className={`title title-secondary`}>
                      beim Bundeswettbewerb KI ein
                    </span>
                    <div className="Youtube-Link-Block">
                      <Link className="button primary" to={"/auth"}>
                        Hier geht’s lang!
                      </Link>
                    </div>
                    <div className="logos-wrapper mt-3">
                      <a
                        href="https://www.instagram.com/bundeswettbewerb.ki/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          className="social-logo"
                          src={`/images/icons/media/instagram.svg`}
                          alt="instagram logo"
                        />
                      </a>
                      <a
                        href="https://discord.gg/J6T5pJyVa8"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          className="social-logo"
                          src={`/images/icons/media/discord.svg`}
                          alt="discord icon"
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCxk0eSeegXCF0fOgMa8DjHA"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          className="social-logo"
                          src={`/images/icons/media/youtube.svg`}
                          alt="youtube logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default EntryPage;

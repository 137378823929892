import React from "react";
import Form from "../../common/form";
import { formatDate, parseDate } from "react-day-picker/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Joi from "joi-browser";
import userService from "../../../services/userService";
import moment from "moment";
import { schools } from "../../../schools.json";
import { all_messages } from "../../../jsons/all_messages_dialog.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

class EditUser extends Form {
  constructor(props) {
    super(props);
    let currentYear = new Date(Date.now()).getFullYear();
    // console.log(String(currentYear + " oder später"))
    this.eligibleYears = [
      String(currentYear + " oder später"),
      String(currentYear - 1),
    ];

    this.state = {
      data: {
        // id: "",
        username: "",
        fullname: "",
        email: "",
        school: "",
        postNumber: "",
        formData: "",
        gender: "",
        parent_email: "",
        is_sonstige: false,
        wohnOrt: "",
        teamName: "",
        yearSchoolExam: "",
      },
      errors: [],
      showPanel: true,
      success: "",
      modal: null,
    };
  }

  showPanel(modalItem) {
    this.setState({
      showPanel: true,
      modal: {
        title: modalItem.title,
        content: modalItem.content,
      },
    });
  }

  closeDefinition = () => {
    this.setState({
      showPanel: false,
      modal: null,
    });
  };

  schema = {
    // id: Joi.string().allow().optional(),
    username: Joi.string().allow("").optional(),
    fullname: Joi.string().allow("").required(),
    email: Joi.string().email().allow("").optional(),
    parent_email: Joi.string().allow("").optional(),
    school: Joi.string().required(),
    postNumber: Joi.number().integer().allow("").required(),
    formData: Joi.date().max(Date.now()),
    gender: Joi.string().allow("").required(),
    wohnOrt: Joi.string().allow("").required(),
    teamName: Joi.string().optional(),
    is_sonstige: Joi.boolean().optional(),
    yearSchoolExam: Joi.string().required(),
  };

  componentWillReceiveProps(nextProps) {
    let birth;
    if (nextProps.response._id) {
      let birthday = moment(nextProps.response.birthday.$date);
      birth = birthday.format("YYYY-MM-DD");
      // console.log(nextProps.response);
      this.setState({
        data: {
          // id: nextProps.response._id.$oid,
          username: nextProps.response.username,
          fullname: nextProps.response.fullname,
          email: nextProps.response.email,
          school: nextProps.response.school,
          formData: birth,
          postNumber: nextProps.response.post_number,
          gender: nextProps.response.gender,
          parent_email: nextProps.response.parent_email,
          wohnOrt: nextProps.response.wohn_ort,
          teamName: nextProps.response.team
            ? nextProps.response.team.team_name
            : "noch nicht gebildet",
          is_sonstige: nextProps.response.school === "Sonstige" ? true : false,
          yearSchoolExam: nextProps.response.year_school_exam
            ? nextProps.response.year_school_exam
            : "",
        },
      });
    }
  }

  handleChangeDate = (date) => {
    const { data } = this.state;
    data.formData = date ? moment(date).format("YYYY-MM-DD") : null;
    this.setState({
      data,
    });
  };

  handleChangeRadio = (event) => {
    const { data } = this.state;
    data["gender"] = event.target.value;
    this.setState({
      data,
    });
  };

  handleInputChange = (event) => {
    const { data } = this.state;
    let school = event.target.value;
    data["school"] = school.replace(" ", "-");
    this.setState({
      data,
    });
  };

  handleBirthdayChange = ({ currentTarget: input }) => {
    const { data } = this.state;

    setTimeout(() => {
      const errors = { ...this.state.errors };
      data.formData = input.value
        ? moment(input.value).format("YYYY-MM-DD")
        : null;

      let showOlderWarning = false;
      if (moment(input.value) < moment(this.getDate(20))) {
        //this.props.onShowOlderWarning(true);
        showOlderWarning = true;
        data.showParent = false;
      } else {
        //this.props.onShowOlderWarning(false);
        showOlderWarning = false;
      }

      let showYoungerWarning = false;
      if (moment(input.value) > moment(this.getDate(13))) {
        //this.props.onShowYoungerWarning(true);
        showYoungerWarning = true;
        data.showParent = true;
      } else {
        //this.props.onShowYoungerWarning(false);
        showYoungerWarning = false;
      }

      this.setState({
        data,
        errors,
        showOlderWarning,
        showYoungerWarning,
      });
    }, 600);
  };

  submitForm = () => {
    const { data, errors } = this.state;
    const {
      // id,
      fullname,
      postNumber,
      gender,
      school,
      wohnOrt,
      yearSchoolExam,
    } = data;

    const validateErrors = this.validate();
    this.setState({ errors: validateErrors || errors });
    if (validateErrors) return;

    userService
      .editUser(fullname, postNumber, gender, school, wohnOrt, yearSchoolExam)
      .then(({ data: response }) => {
        this.setState({
          success: "true",
          data: {
            username: response.username,
            fullname: response.fullname,
            email: response.email,
            school: response.school,
            postNumber: response.post_number,
            parent_email: response.parent_email,
            teamName: response.team
              ? response.team.team_name
              : "noch nicht gebildet",
            is_sonstige: response.school === "Sonstige" ? true : false,
            wohnOrt: response.wohn_ort,
            gender: response.gender,
            yearSchoolExam: response.yearSchoolExam,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          success: "false",
        });
      });

    setTimeout(() => {
      this.setState({
        success: "",
      });
    }, 3000);
  };

  render() {
    let options = [];
    schools.forEach((school) => {
      if (school === "Sonstige") {
        return;
      }
      options.push(<option key={school}>{school}</option>);
    });

    let yearSchoolExamOptions = [<option key="-empty"></option>];
    this.eligibleYears.forEach((year) => {
      yearSchoolExamOptions.push(<option key={year}>{year}</option>);
    });

    let edit_dialog = "";
    all_messages.forEach((dialog) => {
      if (dialog.path === "edit_profile") {
        edit_dialog = dialog;
      }
    });

    const modalItem = {
      title: edit_dialog.title,
      content: edit_dialog.content,
    };
    const { data, errors, success, showPanel, modal } = this.state;
    const {
      username,
      email,
      formData,
      school,
      postNumber,
      gender,
      fullname,
      wohnOrt,
      teamName,
      yearSchoolExam,
    } = data;
    return (
      <div className={`col-md-12`}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <h2 className="text-center">Benutzerprofil</h2>
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="Benutzername">Benutzername</label>
                    <input
                      className="form-control"
                      name="username"
                      value={username}
                      type="text"
                      placeholder={""}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="Email">Email</label>
                    <input
                      className="form-control"
                      name="email"
                      data-teamindex="0"
                      value={email}
                      type="email"
                      disabled
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="Vollständiger Name ">
                      Vollständiger Name{" "}
                    </label>
                    <input
                      className="form-control"
                      name="fullname"
                      value={fullname}
                      onChange={this.handleChange}
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="Geburtstag">Geburtstag</label>
                    <DatePicker
                      //locale='de'
                      dateFormat="yyyy-MM-dd"
                      selected={formData ? moment(formData).toDate() : null}
                      formatDate={formatDate}
                      parseDate={parseDate}
                      placeholderText="Datum JJJJ-MM-TT"
                      onChange={(formData) => this.handleChangeDate(formData)}
                      popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: "viewport",
                        },
                      }}
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
                <hr />

                <div className="form-row">
                  <div className="form-group col-md-5">
                    <label htmlFor="team">Team</label>
                    <input
                      className="form-control"
                      name="team"
                      value={teamName}
                      onChange={this.handleChange}
                      type="text"
                      disabled
                    />
                    <span style={{ fontSize: "20px", zIndex: 123 }}>
                      <span
                        className="question_icon"
                        onClick={this.showPanel.bind(this, modalItem)}
                      >
                        <FontAwesomeIcon
                          className="icon"
                          icon={faQuestionCircle}
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <hr />

                <div className="row">
                  <div className="form-group col-md-6">
                    <label className={""} htmlFor="Schule">
                      Schule
                    </label>
                    <div>
                      <input
                        list="schools"
                        name="school"
                        value={school}
                        onChange={this.handleInputChange}
                        type="text"
                        placeholder={"Schule"}
                        className={
                          errors.school
                            ? "invalid form-control"
                            : "form-control"
                        }
                      />
                      <datalist id="schools">{options}</datalist>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <p>Jahr des Schulabschlusses</p>
                    <select
                      id="yearSchoolExam"
                      name="yearSchoolExam"
                      value={yearSchoolExam}
                      onChange={this.handleChange}
                      className={`form-control ${
                        errors.yearSchoolExam ? "invalid" : ""
                      }`}
                    >
                      {yearSchoolExamOptions}
                    </select>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className={""} htmlFor="PLZ">
                      PLZ
                    </label>
                    <input
                      name="postNumber"
                      value={postNumber}
                      onChange={this.handleChange}
                      type="text"
                      placeholder="PLZ"
                      className={`form-control ${
                        errors.postNumber ? "invalid" : ""
                      }`}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className={""} htmlFor="Wohnort">
                      Wohnort
                    </label>
                    <input
                      name="wohnOrt"
                      value={wohnOrt}
                      onChange={this.handleChange}
                      type="text"
                      placeholder=""
                      className={`form-control ${
                        errors.wohnOrt ? "invalid" : ""
                      }`}
                    />
                  </div>
                  <div className="col-md-6">
                    <p>Geschlecht</p>
                    <div className="form-check-inline">
                      <label htmlFor="männlich" className={`form-check-label`}>
                        <input
                          type="radio"
                          id="männlich"
                          name="gender"
                          value={"männlich"}
                          checked={gender === "männlich"}
                          onChange={this.handleChangeRadio}
                          className="form-check-input"
                        />
                        Männlich
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label htmlFor="weiblich" className={`form-check-label`}>
                        <input
                          type="radio"
                          id="weiblich"
                          name="gender"
                          value={"weiblich"}
                          checked={gender === "weiblich"}
                          onChange={this.handleChangeRadio}
                          className="form-check-input"
                        />
                        Weiblich
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label htmlFor="divers" className={`form-check-label`}>
                        <input
                          type="radio"
                          id="divers"
                          name="gender"
                          value={"divers"}
                          checked={gender === "divers"}
                          onChange={this.handleChangeRadio}
                          className="form-check-input"
                        />
                        Divers
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`button-div`}>
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  Speichern
                </button>
                {success === "true" && (
                  <span className="success-state">
                    Deine Veränderungen wurden erfolgreich gespeichert!
                  </span>
                )}
                {success === "false" && (
                  <span className="failed-state">
                    Deine Veränderungen konnten nicht gespeichert werden.
                  </span>
                )}
                {/* {schoolError &&
                                    <span className='failed-state'>{schoolError}</span>
                                } */}
              </div>
              {showPanel && modal && (
                <div className="overlay-panel-container">
                  <div className="overlay-panel">
                    <div className="definition-header">{modal.title}</div>
                    <div className="section">
                      <p className="text-justify">{modal.content}</p>
                    </div>
                    <div className="close" onClick={this.closeDefinition}></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUser;

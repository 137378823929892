import React from "react";
import Form from "../common/form";
import "../../datepicker.css";
import EditUser from "./components/edit_user";
import userService from "../../services/userService";

class UserProfile extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: [],
    };
  }

  componentDidMount() {
    userService.getUser().then(({ data: response }) => {
      this.setState({
        data: response,
      });
    });
  }

  render() {
    const { data } = this.state;

    return (
      <React.Fragment>
        <section className="content question">
          <div className="container-fluid">
            <div className="container-limit">
              <div className={`user-profile`}>
                <EditUser response={data} />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default UserProfile;

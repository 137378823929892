import http from './httpService'

const {
    REACT_APP_WEB_API_URL: web_api_url,
} = process.env;

export async function get(page) {
    return await http.get(`${web_api_url}/team-findings/${page}`);
}

export async function getTeam(id) {
    return await http.get(`${web_api_url}/team-finding/${id}`);
}

export async function add(title, description) {
    return await http.post(`${web_api_url}/team-finding`, { title, description })
}

export async function addComment(teamId, comment) {
    return await http.post(`${web_api_url}/team-finding/comment`, { teamId, comment });
}

export default {
    get,
    getTeam,
    add,
    addComment
}
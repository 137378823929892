import React from 'react';
import Pagination from 'react-js-pagination';

const MyPagination = ({ currentPage, itemsCountPerPage = 10, totalItemsCount, onChange }) => {
    return (
        <Pagination
            activePage={currentPage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={3}
            onChange={onChange}
            itemClass='page-item'
            linkClass='page-link'
        />
    );
}

export default MyPagination;
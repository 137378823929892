import React from "react";
import { Link } from "react-router-dom";
import navigationHelper from "./navigations-finder.json";

const breadcrumb = (props) => {
  const getbreadcrumb = (path) => {
    if (path === "/") path = "";

    let appendItem = null;

    let items = path.split("/");

    let navs = items
      .map((item) => {
        if (item === "view-team-findung") {
          return {
            path: "view-team-findung",
            title: "Team Finden",
            link: "/view-team-findung/" + items[items.length - 1],
          };
        }
        return navigationHelper.navigations.find(
          (navigation) => navigation.path === item
        );
      })
      .filter((item) => item);

    if (appendItem) navs.push(appendItem);
    return navs;
  };

  let itemsVal = getbreadcrumb(props.path);
  // console.log(itemsVal)

  return (
    <React.Fragment>
      <div className="breadcrumb-main">
        {itemsVal.map((navItem) => (
          <Link to={navItem.link} key={navItem.path}>
            {!(navItem.link === "/") && <span>{" > "}</span>} {navItem.title}{" "}
          </Link>
        ))}
      </div>
    </React.Fragment>
  );
};
export default breadcrumb;

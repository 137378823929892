import React, { Component } from "react";
import auth from "../../services/authService";
import Loading from "../common/loading"

class Logout extends Component {
    componentDidMount() {
        auth.logout();

        window.location = "/entry-page";
    }

    render() {
        return (<React.Fragment>
            <Loading message="Logging Out.." />
        </React.Fragment>);
    }
}

export default Logout;

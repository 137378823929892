import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import teamFindungService from '../../services/teamFindungService';
import MyPagination from '../common/my-pagination';
import { getUserIdColor, shortTitle } from '../../services/authService';
import Identicon from 'react-identicons';

class TeamFindung extends Component {
    _isMounted = false;
    state = {
        teams: [],
        currentPage: 1,
        totalTeams: 0
    }

    componentDidMount() {
        this._isMounted = true;
        this.getTeams(1);
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    getTeams = page => {
        teamFindungService.get(page).then(({ data }) => {
            let teamsObj = JSON.parse(data.teams);
            
            let teams = [];
            teamsObj.forEach(team => {
                teams.push({
                    id: team._id['$oid'],
                    title: team.title,
                    username: team.username,
                    userId: team.userId
                })
            });
            if(this._isMounted)
            {
                this.setState({
                    teams,
                    totalTeams: data.totalNumberOfTeams
                });
            }
        });
    }

    goTo = id => {
        this.props.history.push(`/view-team-findung/${id}`);
    }

    handlePageChange = page => {
        if (page === this.state.currentPage) return false;

        this.setState({
            currentPage: page
        });
        this.getTeams(page);
    }

    render() {
        const { teams, currentPage, totalTeams } = this.state;
        return (
            <React.Fragment>
                <section className="content question">
                    <div className="container-fluid">
                        <div className="container-limit my-limit">
                            <div className="bestenliste">
                                <div className="header1 font-main-header text-center primary-color-blue-foreground">
                                        <span>Finde dein Team - neuer Eintrag</span>
                                </div>
                                <div className="header2 font-sub-header text-center">
                                        <span>Du hast eine Idee, aber dir fehlt das Team? Du willst dich einem Team anschließen?</span><br/>
                                        <span>Hier kannst du deine Idee posten oder dein zukünftiges Team kontaktieren. </span>
                                </div>
                                <div className="title">
                                    <div className="text-right mt-3">
                                        <Link to='/team-findung/add-team-findung' className='btn btn-primary'>+ Neuer Eintrag</Link>
                                    </div>
                                </div>
                                <div className="table-list">
                                    {
                                        teams.map(team =>
                                            <div key={team.id} onClick={() => this.goTo(team.id)} className="liste-wrapper cursor-pointer">
                                                <div className="title-team">
                                                <span>{[shortTitle(team.title)]}</span>
                                                </div>
                                                {/* <div className="avatar-team">
                                                    <span><Identicon palette={[getUserIdColor(team.userId)]} string={team.username} /></span>
                                                </div> */}
                                                <div className="name-team">
                                                    <span>{team.username}</span>
                                                </div>
                                                {/* <div className="avatar-list border-wrapper">
                                                    <span><Identicon palette={[getUserIdColor(user.id)]} string={user.username} /></span>
                                                </div> */}
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="pagination-team">
                                    <MyPagination currentPage={currentPage} totalItemsCount={totalTeams} onChange={this.handlePageChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </section>
            </React.Fragment>
        );
    }
}

export default TeamFindung;

import React from 'react'

export default function loading(props) {
    return (
        <div className="loading-container">
            <div className="loading-panel">
                {!props.message?"Loading...":props.message}
            </div>
            
        </div>
    )
}

import React from 'react';
import Form from '../common/form';
import Joi from 'joi-browser';
import teamFindungService from '../../services/teamFindungService';

class AddTeamFindung extends Form {
    _isMounted = false;
    state = {
        data: {
            title: '',
            description: ''
        },
        errors: {},
        loading: false,
        submitError: false
    }

    schema = {
        title: Joi.string().required(),
        description: Joi.string().required()
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    submitForm = () => {
        this.setState({
            loading: true,
            submitError: false
        });

        const { title, description } = this.state.data;
        teamFindungService.add(title, description).then(({ data }) => {
            this.props.history.push('/team-findung');
        }).catch(err => {
            if(this._isMounted)
            {
                this.setState({
                    loading: false,
                    submitError: true
                });
            }
        })
    }

    render() {
        const { data, errors, loading, submitError, canSubmitProp, validate, } = this.state;
        const { title, description } = data;
        return (
            <section className="content question">
                <div className="container-fluid">
                    <div className="container-limit my-limit">
                        <div className="col-md-12">
                            <div className="header1 font-main-header text-center primary-color-blue-foreground">
                                <span>Finde dein Team - neuer Eintrag</span>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <label for='labelDesc'>Titel</label>
                                    <input name='title' className="form-control" id='title' value={title} onChange={this.handleChange} type="text" />
                                    <label for='labelDesc' >Schreibe deutlich in den Titel, ob du dich einem Team anschließen möchtest, oder Personen zur Umsetzung deiner Idee suchst.</label>
                                </div>
                                <div className="beschreibung">
                                    <label for='labelDesc'>Beschreibung</label>
                                    <textarea className="form-control" name='description' id='description' value={description} onChange={this.handleChange}></textarea>
                                </div>
                                {this.renderSubmitButton('Veröffentlichen', loading, 'btn btn-primary button-team')}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AddTeamFindung;
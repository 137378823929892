import React from 'react';
import Form from '../common/form';
import Joi from 'joi-browser'
import authService from '../../services/authService'
import logo from '../../img/logo_bwki.png';


class ForgotPassword extends Form {
    state = {
        data: {
            email: ''
        },
        showEmail: true,
        loginError: '',
        errors: {}
    }

    schema = {
        email: Joi.string().email().required()
    }

    componentWillReceiveProps(props) {
        const { data } = this.state;
        if (props.activeTab === 'forgot') {
            data.email = '';
            this.setState({
                showEmail: true,
                loginError: '',
                data
            })
        }
    }

    submitForm = () => {
        this.setState({
            loading: true
        })
        const { email } = this.state.data;
        authService.forgotPassword(email).then(({ data }) => {
            this.setState({
                showEmail: false,
                loginError: '',
                loading: false
            })
        }).catch(err => {
            let error = "Es ist ein unerwarteter Fehler aufgetreten.";
            if (err.response && err.response.status === 400) {
                error = err.response.data.error;
            }

            this.setState({
                showEmail: true,
                loginError: error,
                loading: false
            })
        })
    }

    render() {
        const user = authService.getCurrentUser();
        if (user) this.props.history.push('/');
        const { errors, loginError, loading } = this.state;

        const { email } = this.state.data;
        const { showEmail } = this.state;
        const { activeTab } = this.props;
        return (
            <div className={`content-login-wrapper forgot-content ${activeTab === 'forgot' ? 'active' : ''}`}>
                {
                    showEmail &&
                    <React.Fragment>
                        <div className="login-logo">
                            <img alt='logo' src={logo} />
                            <h1> <span>Bundeswettbewerb</span> <br /> Künstliche Intelligenz </h1>
                        </div>
                        <form >
                            <div className="input-wrapper">
                                <input className={`${errors.email ? 'invalid' : ''}`} name='email' onChange={this.handleChange} value={email} type="text" placeholder="Email" />
                            </div>
                        </form>
                        {
                            loginError &&
                            <div className="error text-center">
                                <span className="text-danger">{loginError}</span>
                            </div>
                        }
                        <div className="button-login">
                            <button disabled={loading ? true : this.validate()} type='button' onClick={this.handleSubmit}>{loading ? "Bitte warten..." : "Absenden"}</button>
                        </div>
                    </React.Fragment>
                }
                {
                    !showEmail &&
                    <div className="sentEmail">
                        <h1>Du hast Post! Bitte klicke auf den Link in der dir soeben zugesandten Email.</h1>
                    </div>
                }
            </div>
        );
    }
}

export default ForgotPassword;